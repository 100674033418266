import React, { Component } from 'react'
import cn from 'clsx'
import Layout from '@layout'
import FullscreenImage from '@components/FullscreenImage'
import FrontpageBgImage from '@images/frontpage-bg_new.jpg'
import Logo from '@images/logo_white_line-750.png'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
    this.onImageLoaded = this.onImageLoaded.bind(this)
  }

  onImageLoaded() {
    this.setState({ loaded: true })
  }

  render() {
    const pageClass = cn('frontpage', { loaded: this.state.loaded })
    return (
      <Layout>
      <div className={pageClass}>
        <FullscreenImage image={FrontpageBgImage} loaded={this.onImageLoaded} />
        <div className="container">
          <img src={Logo} className="pure-img logo" />
        </div>
      </div>
      </Layout>
    )
  }
}

export default Home
